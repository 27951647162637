import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const baseUrl = "https://jellyfish-app-ecbpl.ondigitalocean.app/";
const LoginApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    authenticate: builder.mutation({
      query: (credentials) => ({
        url: "api/v1/login",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: credentials,
      }),
    }),
  }),
});
export const { useAuthenticateMutation } = LoginApi;
export default LoginApi;
