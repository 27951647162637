import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './Components/Login'
import Page1 from './Components/Page1'
import Log from './Components/Logi'
import Page2 from './Components/Page2'

const App = () => (
  <>
    <Routes>
      <Route path='/' element={<Login/>}></Route>
      <Route path='/login' element={<Log/>}></Route>
      <Route path='/page1' element={<Page1/>}></Route>
      <Route path='/page2' element={<Page2/>}> </Route>
    </Routes>

  </>
)

export default App