import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const handleLogin = async (e) => {
    e.preventDefault();
    const credentials = `${username}:${password}`;
   const base64Credentials = btoa(credentials);

   console.log(base64Credentials);
  await  axios.post(
        "https://jellyfish-app-ecbpl.ondigitalocean.app/api/v1/login",{},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic '+ base64Credentials
          }
        }
      )
      .then((response) => {
    
        const { token } = response.data.access_token;
        console.log(response.data.access_token);
        console.log(token);
        console.log(response.status);
        console.log(response);
        console.log("Login successful:", response.data);
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
        // Save the token in localStorage
        // localStorage.setItem("token", token);
        // if (token) {
        //   navigate("/Page1");
        // }
        // Handle successful login
        
      })
      .catch((error) => {
        alert("Invalid credentials");
        console.error("Login failed:", error);
        
      });
  };

  return (
    <>
      <div className="wrapp">
        <div className="new_session">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="session_wrap">
                  <div className="session_text">
                    <span>
                      <img
                        style={{ height: "55px", width: "55px" }}
                        src="assets/images/logo.png"
                      />
                    </span>
                    <h3>Login</h3>
                  </div>
                  <div className="button_session">
                    <form onSubmit={handleLogin}>
                      <div className="logg">
                        <input
                          placeholder="username"
                         
                          onChange={(e) => setUsername(e.target.value)}
                          required
                        />{" "}
                        <input
                          placeholder="password"
                         
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                      <button type="submit">Login</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
