import React from "react";

const Page1 = () => {
  return (
    <>
      <div className="wrapp">
        <div className="new_session">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="session_wrap">
                  <div className="session_text">
                    <h3>Begin a new session</h3>
                    <p>tt•s good to have you here. With every session,
                      we're getting closer to goats. Let•s keep
                      working on them.</p>
                    <strong>vmat shouid we focus on today?</strong>
                  </div>
                  <div className="button_session">
                    <form action="/action_page.php">
                    <span>
                      <button type="text" placeholder="" id="fname" name="fname">I want to enter my own topic</button>
                      <a href="#">
                          <img src="assets/images/icons/arrow left.png" alt="" /></a></span>
                    <button id="fname" name="fname">I want to enter my own topic</button>
                    <button id="fname" name="fname">I want to enter my own topic</button>
                    <button id="fname" name="fname">I want to enter my own topic</button>
                    <button id="fname" name="fname">I want to enter my own topic</button>
                    <button id="fname" name="fname">I want to enter my own topic</button>
                    <button id="fname" name="fname">I want to enter my own topic</button>



                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
   
    </>
  );
};

export default Page1;
