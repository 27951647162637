import React from 'react'
import axios from "axios";
const Log = () => {
  
async function logUserIn() {
  try {
    
    const response = await axios.post(
      'https://jellyfish-app-ecbpl.ondigitalocean.app/api/v1/login',
    
      {
        auth: {
          username: 'anand',
          password: 'password',
        },

        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data;
  } catch (err) {
    console.log(err);
    
  }
}

logUserIn().then(data => {
  console.log(data);
});

  return (
    <>Login</>
  )
}

export default Log