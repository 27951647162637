import React from "react";

const Page2 = () => {
  return (
    <>
      <div className="wrapp">
        <div className="new_session">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="session_wrap">
                  <div className="session_text">
                    <h3>Begin a new session</h3>
                    <p>Please briefly describe what it is about</p>
                  </div>
                  <div className="input_session">
                    <form action="/action_page.php">
                      <textarea
                        className="textareafiled"
                        placeholder="Describe your topic..."
                        
                        rows={4}
                        cols={50}
                      ></textarea>
                    </form>
                  </div>
                  <div className="arrow_wrap">
                    <a href="#">
                      {" "}
                      <figure>
                        <img
                          src="assets/images/icons/arrow circle.png"
                          alt=""
                        />
                      </figure>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page2;
